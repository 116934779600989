<template>
  <div>
    <b-button
      v-b-modal.modal-create-up-caveat
      variant="outline-primary"
      @click="initialSetup"
    >
      Create New
    </b-button>

    <!-- select 2 demo -->
    <b-modal
      id="modal-create-up-caveat"
      title="Create New Tracker"
      no-close-on-backdrop
      centered
      ok-title="submit"
      cancel-variant="outline-secondary"
    >
      <b-card-text>
        <b-overlay
          :show="$store.state.firTrackerStore.isNewFIRLoading"
          rounded="sm"
          variant="transparent"
          opacity="0.5"
          blur="2px"
        >
          <validation-observer ref="matterForm" #default="{ invalid }">
            <b-form @submit.prevent="addTracker">
              <!-- Select Court -->
              <validation-provider
                #default="{ errors }"
                name="Court"
                vid="court"
                rules="required"
              >
                <b-form-group label="Select Court" label-for="court">
                  <v-select
                    v-model="CourtSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="CourtOption"
                    label="text"
                    placeholder="Select Court"
                    @input="onCourtSelect"
                  />
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="District"
                vid="district"
                rules="required"
              >
                <b-form-group label="Select District" label-for="district">
                  <v-select
                    v-model="DistrictSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="DistrictOptions"
                    label="name"
                    placeholder="Select District"
                    @input="onDistrictSelect"
                  />
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="Petitioner Name"
                vid="petitioner-name"
                rules="required"
              >
                <b-form-group
                  label-for="petitioner-name"
                  label="Party/Petitoner/Respondent Name"
                >
                  <b-form-input
                    id="petitioner-name"
                    rules="required"
                    v-model="PetitionerName"
                    placeholder="Party/Petitoner/Respondent Name"
                  />
                </b-form-group>
              </validation-provider>
            </b-form>
          </validation-observer>
          <template #overlay>
            <div class="text-center">
              <b-spinner variant="secondary"></b-spinner>
              <p id="cancel-label">{{ loadingMsg }}</p>
            </div>
          </template>
        </b-overlay>
      </b-card-text>

      <!-- Footer -->
      <template #modal-footer>
        <!-- By Matter Footer -->
        <div class="w-100">
          <div>
            <div>
              <b-button
                variant="primary"
                class="float-right"
                type="submit1"
                :disabled="$store.state.upCaveatStore.isNewFIRLoading"
                @click="addTracker"
              >
                Submit
              </b-button>
            </div>
            <div>
              <b-button
                variant="outline-dark"
                class="float-right mr-1 ml-1"
                type="cancel"
                @click="$bvModal.hide('modal-create-up-caveat')"
              >
                Cancel
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BCardText,
  BFormTextarea,
  BFormGroup,
  BTabs,
  BTab,
  BOverlay,
  BFormRadio,
  BSpinner,
  BFormInvalidFeedback,
  BFormDatepicker,
  BListGroup,
  BListGroupItem
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BCardText,
    BFormInvalidFeedback,
    BFormGroup,
    BTab,
    BTabs,
    vSelect,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BSpinner,
    BFormDatepicker,
    BListGroup,
    BListGroupItem
  },
  directives: {
    'b-modal': VBModal,
    Ripple
  },
  data() {
    return {
      // Matter
      CourtSelected: null,
      CourtOption: null,
      PetitionerName: null,
      DistrictOptions: [],
      DistrictSelected: null,
      loadingMsg: '',
      // validation rules
      required
    }
  },
  props: ['refreshList'],
  methods: {
    getCourts() {
      let allC = []
      const courtsList = []
      // ** Court Setup Starts
      const ll = this.$store.state.courtStore.courtsData
      console.log({ SDS: ll })
      if (ll) {
        const hcl = ll.highCourts
          .filter(function (key) {
            if (Object.keys(key).includes('caveat_section_enabled')) {
              return key.enabled && key.caveat_section_enabled
            }
            return false
          })
          .map(function (key) {
            return {
              value: key._id,
              text: key.title,
              cslug: key.cli_slug,
              unique_id: key.unique_id
            }
          })
        const hc = {
          label: 'High Court',
          options: hcl
        }
        const dcl = ll.districtCourts
          .filter(function (key) {
            if (Object.keys(key).includes('caveat_section_enabled')) {
              return key.enabled && key.caveat_section_enabled
            }
            return false
          })
          .map(function (key) {
            return {
              value: key._id,
              text: key.title,
              cslug: key.cli_slug,
              unique_id: key.unique_id
            }
          })
        const dc = {
          label: 'District Court',
          options: dcl
        }

        courtsList.push(hc)
        courtsList.push(dc)
        allC = [...hcl, ...dcl]
      }
      this.CourtOption = allC
    },
    initialSetup() {
      const years = []
      for (var i = new Date().getFullYear(); i >= 1901; i--) {
        years.push(i)
      }
      this.years = years

      this.CourtSelected = null
      this.PetitionerName = null
      this.DistrictOptions = []
      this.DistrictSelected = null
    },
    clearAllFields() {
      // Matter
      ;(this.Title = null),
        (this.CourtSelected = null),
        (this.PetitionerName = null),
        (this.DistrictOptions = []),
        (this.DistrictSelected = null)
    },
    // Matters
    onCourtSelect(event) {
      console.log(event)
      this.$store
        .dispatch('upCaveatStore/fetchUPDistrict')
        .then((response) => {
          console.log('Case Types --> ', response)
          if (response) {
            this.DistrictOptions = response.districts
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    onDistrictSelect(event) {
      console.log(event)
    },
    addTracker() {
      this.loadingMsg = 'Please Wait, Adding Tracker!'
      this.CurrentMatter = null
      event.preventDefault()
      this.$refs.matterForm.validate().then((success) => {
        if (success) {
          let data = {
            court_id: this.CourtSelected.value,
            petitioner_name: String(this.PetitionerName),
            district: String(this.DistrictSelected.value)
          }
          console.log('Data C', data)
          store
            .dispatch('upCaveatStore/addTracker', {
              ctx: this,
              payload: data
            })
            .then((response) => {
              console.log({ Matter: response })
              if (response != null) {
                if (response.success) {
                  this.refreshList()
                }
              }
            })
        }
      })
    }
  },
  beforeMount() {
    this.getCourts()
  },
  setup(props, context) {
    const { refFormObserver, getValidationState, resetForm } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
